var Preloader = function(args) {
    this.id = args.id || "preloader";
    this.container = document.getElementById(this.id);
    this.init = function() {
        document.body.classList.add("loading");
        // window.addEventListener("load", this.hide.bind(this), false);
    }
    this.hide = function() {
        document.body.classList.remove("loading");
        TweenLite.to(this.container, 0.5, {
            ease: Power2.easeOut,
            delay: 0.1,
            y: "-100%",
            display: "hide"
        });
    }
    this.init();
    return this;
}
