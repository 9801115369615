var Modals = {
    init: function() {
        Modals.addEvents();
    },
    addEvents: function() {
        $(document).on("click", ".open-modal", function() {
            var target = $(this).data("target");
            Modals.open(target);
        });
        $(document).on("click", ".modal", function() {
            if (!$(this).find(".content").is(":hover")) {
                return Modals.close();
            }
        });
        $(document).on("keyup", function(event) {
            switch (event.which) {
                case 27:
                    return Modals.close();
                    break;
            }
        });
        $(document).on("click", ".close-modal", function() {
            var target = $(this).data("target");
            return Modals.close(target);
        });
    },
    open: function(target) {
        $("body, html").addClass('no-scroll');
        if (target !== undefined) $(target).fadeIn();
    },
    close: function(target) {
        $(".modal").fadeOut();
        $("body, html").removeClass('no-scroll');
        if (target === undefined) {
            $(".modal").fadeOut();
        } else {
            $(target).fadeOut();
        }
    }
}