//= require "vendor/**/*.js"
//= require "libraries/greensock/TweenLite.js"
//= require "libraries/greensock/TweenMax.js"
//= require "libraries/greensock/plugins/CSSPlugin.js"
//= require "libraries/greensock/easing/EasePack.js"
//= require "libraries/owl.carousel.min.js"
//= require "libraries/animated-scroll-to.js"
//= require "modules/Preloader.js"
//= require "modules/Modals.js"
//= require "modules/menu.js"

var App = {
	preloader: null,
	isMobile: null,
	init: function(){
		window.PRELOADER = new Preloader("#preloader");
		$('html').removeClass('no-js');
        App.addEvents();
        Modals.init();
			 	// App.scrollTo(0);
				if (isMobileDevice()) {
					Menu.constructor();
				}
				App.isMobile = isMobileDevice() ? 'mobile' : 'desktop';
        $('body').addClass(App.isMobile);
      	$('#container-slides').owlCarousel({
        	loop:true,
					nav: true,
					items:1,
        	//autoplay:true,
        	// autoplayTimeout:6000,
      	});
	},
	loaded: function(){
		window.PRELOADER.hide();
		TweenLite.delayedCall(0.1, function(){
        $(".item-animable").each(function(index, el) {
           showItem(el, (index * 0.05)+0.2);
        });
    	});
	},
  addEvents: function() {
  	/* Prevent Default in all links with href === '#' */
    $(document).on("click", "a[href='#']", function(event) {
    	event.preventDefault();
    });
		// scroll
		$(document).on("click", ".menu-link", function(e) {
				e.preventDefault();
				var _target = $(this).attr('href');
				var _top = $(_target).offset().top;
				animateScrollTo(_top);
		});
		// boton conoce mas
		$(document).on("click", ".btn", function(event) {
			var $this = $('.conoce');
		  $('.edge-bottom').slideToggle(10);
			$this.slideToggle(400);
			$('.inner').slideToggle(600);
		});
    /** Good practice, add toggle class only in 'anchor' or 'button' */
    $(document).on("click", ".toggle", function() {
        var _target = $(this).data("target");
        /**/
        var $target = _target === undefined ? $(this) : $(_target);
        $target.toggleClass('active');
    });
  }
}
function isMobileDevice() {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

function showItem(item, delay) {
    TweenLite.to(item, 0.5, {
        ease: Power2.easeOut,
        delay: delay,
        opacity: 1,
        y: 0
    });
}
$(document).ready(App.init());
$(window).on("load", App.loaded());
