var Menu = {

    constructor: function() {
        this.menuBtn = "#toggle-menu";
        this.tl = null;
        this.isMobile = false;
        Menu.init();
    },

    init: function() {
        this.isMobile = $("body").hasClass('mobile');
        this.addEvents();
    },

    addEvents: function() {
        $(document).on("click", '.menu-link', () => {
            this.hide();
        });
        $(document).on("click", this.menuBtn, () => {
            if ($(this.menuBtn).hasClass('active')) this.hide();
            else this.show();
        });
    },

    show: function() {
        $("#main-nav-container, " + this.menuBtn).addClass('active');
        TweenMax.staggerTo("#main-nav-content li", 0.25, {
            ease: Power2.easeOut,
            y: 0,
            rotationX: "0deg",
            opacity: 1
        }, 0.075);
    },

    hide: function() {
        TweenMax.killAll();
        $("#main-nav-container, " + this.menuBtn).removeClass('active');
        TweenMax.staggerTo("#main-nav-content li", 0.75, {
            ease: Power2.easeOut,
            y: 10,
            rotationX: "50deg",
            opacity: 0
        }, 0.075);
    }
}
